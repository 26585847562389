<template>
  <div class="badges">
    <div
      v-for="(item, index) in tagLabels"
      :key="`product_label_${index}`"
    >
      <DiscountBadge
        v-if="item.type === 'discount'"
        :item="item"
        :product="product"
      />
      <PromotionBadge
        v-else-if="item.type === 'promotion'"
        :item="item"
        :product="product"
      />
      <DefaultBadge
        v-else
        :item="item"
        :product="product"
      />
    </div>
  </div>
</template>

<script>
import isNil from 'lodash/isNil';
import { checkIfDateExpired } from 'theme/helpers/date';

export default {
  name: 'Badges',
  components: {
    DiscountBadge: () => process.browser ? import('theme/components/product/discount-badge') : null,
    PromotionBadge: () => process.browser ? import('theme/components/product/promotion-badge') : null,
    DefaultBadge: () => process.browser ? import('theme/components/product/default-badge') : null
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    allowDiscountLabel: {
      type: Boolean,
      default: true
    },
    allowPromotionLabel: {
      type: Boolean,
      default: true
    },
    allowNoveltiesLabel: {
      type: Boolean,
      default: true
    },
    allowMarkdownLabel: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    isDateInRange (label) {
      const { date_from, date_to } = label;
      if (!date_from || !date_to) return false;

      const fromDate = this.getDateFromString(date_from);
      const toDate = this.getDateFromString(date_to);
      const isExpired = checkIfDateExpired(date_to);

      if (!fromDate || !toDate) return false;

      const now = new Date();
      return fromDate < now && !isExpired;
    },
    getDateFromString (dateString) {
      const date = new Date(dateString);
      if (isNaN(Date.parse(date))) {
        return null;
      }
      return date;
    }
  },
  computed: {
    showDiscount () {
      const discount = this.product && this.product.special_price_discount;
      return this.product && this.showDiscountDate && !isNil(discount) && Number(discount) !== 0 &&
          this.allowDiscountLabel;
    },
    showDiscountDate () {
      return this.product && this.product.special_price_to_date &&
          !checkIfDateExpired(this.product.special_price_to_date) && this.allowDiscountLabel;
    },
    showLabelPromotion () {
      return Boolean(this.promotionLabels.length) && this.allowPromotionLabel;
    },
    showLabelNovelties () {
      const { is_new, news_from_date, news_to_date } = this.product || {};
      const now = new Date();

      return is_new && news_from_date && news_to_date &&
          new Date(news_from_date) < now && now < new Date(news_to_date) && this.allowNoveltiesLabel;
    },
    promotionLabels () {
      return (this.product.product_label || []).filter(e => e.text && this.isDateInRange(e));
    },
    showMarkdownTag () {
      return this.product && this.product['markdown_discount'] && this.allowMarkdownLabel;
    },
    showOnlinePromotionTag () {
      return !!(this.product && this.product['online_promotion_in_stores'] && this.product['online_promotion_in_stores'].length)
    },
    showBordTag () {
      return !!(this.product && this.product['boardProduct'])
    },
    tagLabels () {
      const labels = []

      if (this.showDiscount) {
        labels.push({
          type: 'discount',
          color: '#BFEAB6',
          text: '',
          position: labels.length + 1
        })
      }

      if (this.showLabelNovelties) {
        labels.push({
          type: 'novelties',
          color: '#E1F67A',
          text: this.$t('Novelty'),
          position: labels.length + 1
        })
      }

      if (this.showMarkdownTag) {
        labels.push({
          type: 'markdown',
          color: '#FC9F9F',
          text: `${this.$t('Markdown')} -${this.product.markdown_discount}%`,
          position: labels.length + 1
        })
      }

      if (this.showLabelPromotion) {
        this.promotionLabels.forEach(e => {
          if (e.text) {
            labels.push({
              ...e,
              type: 'promotion',
              class: 'badge-promotion',
              color: e.color || '#61ABB6',
              text: e.text.slice(0, 22),
              position: labels.length + 1
            });
          }
        });
      }

      if (this.showOnlinePromotionTag) {
        labels.push({
          type: 'online_promotion',
          color: '#ffff0d',
          text: this.$t('Online only label'),
          position: labels.length + 1
        })
      }

      if (this.showBordTag) {
        labels.push({
          type: 'bord',
          color: '#e4e3f1',
          text: this.$t('Bord'),
          position: labels.length + 1
        })
      }

      return labels
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

::v-deep {
  .product-label {
    &__badge {
      color: var(--black);
      font-size: var(--font-size-12);
      line-height: var(--spacer-20);
      font-weight: var(--font-medium);
      max-width: calc(100% - var(--spacer-20));
      height: var(--spacer-20);
      padding: 0 var(--spacer-8);
      position: absolute;
      left: var(--spaser-10);
      z-index: 9;

      @include for-desktop {
        top: 0;
      }

      @media (min-width: $tablet-min) and (max-width: $tablet-max) {
        top: 0;
      }

      &_text {
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        max-width: 100%;
      }

      &_left {
        position: absolute;
        left: - px2rem(3.5);
        display: block;
        border-top: var(--spacer-10) solid transparent;
        border-bottom: var(--spacer-10) solid transparent;
        border-right-width: var(--spacer-4);
        border-right-style: solid;
      }

      &_right {
        position: absolute;
        right: - px2rem(3.5);
        border-top: var(--spacer-10) solid transparent;
        border-bottom: var(--spacer-10) solid transparent;
        border-left-width: var(--spacer-4);
        border-left-style: solid;
      }

      &-position-1 {
      top: 5px;
      }

      &-position-2 {
        top: 30px;
      }

      &-position-3 {
        top: 55px;
      }

      &-position-4 {
        top: 80px;
      }

      &-position-5 {
        top: 105px;
      }
    }
  }
}
</style>
